
.App {
    text-align: center;
    min-height: 70%;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: white;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

/*
    this our calendar css
 */
.myContainer{
    margin: 50px auto 50px;
    height: 100%;
    min-height: 100%;
    background-color: #00aced;
}
.calendar-container,
.calendar,
.calendar-header,
td,
tr {
    padding: 0;
    margin: 0;
}

.calendar-container {
    border: 2px solid skyblue;

}

.calendar {
    background-color: white;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

.calendar-header {
    border-bottom: 1px dashed skyblue;
    font-size: 1.6em;
}

.calendar-header td {
    border-spacing: 0;
    padding-left: 5px;
}

.day,
.empty-slot,
.week-day {
    text-align: center;
    height: 40px;
}

.week-day {
    font-size: 0.8em;
}

.day {
    font-size: 0.8em;
}

.day {
    cursor: pointer;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.current-day {
    background-color: #4285f4;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 2%;
    color: white;
}
.selected-day {
    background-color:yellow;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 2%;
    color: black;
}

.month-popup {
    position: absolute;
    padding: 5px;
    background: white;
    border: 2px solid skyblue;
}

.month-popup div {
    padding: 0.3em;
}

.nav-month {
    position: absolute;
    top: 0.6em;
    font-size: 0.6em;
    right:3px;
}

.prev {
}

.next {
}

.editor-year {
    max-width: 3.6em;
}

.label-month,
.label-year {
    font-size: 0.6em;
}

.label-month {
    cursor: pointer;
}