.myBorderLeftDiv{
    border-width: 2px;
    border-color: #00aced;
    border-radius: 5px;
}

.my-custom-scrollbar {
    position: relative;
    height: 100%;
    overflow-y: scroll;
}
.table-wrapper-scroll-y {
    display: block;
}