body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.myFooterBox{
    background-color: #7b8188;
    width: 100%;
    bottom: 0;
    color: white;
    height: 50px;
    position: relative;
    padding-top: 15px;
    text-align: center;
    font-weight: bold;
    opacity: 0.6;
    margin-top: 3%;
}


.headerLine{
    height: 2px;
    border-radius: 1px;
    margin-left: 20px;
    right: 20px;
    width: 90vh;
}
.myLinkHeader{
    pointer-events: all;
    color: currentColor;
   /* text-decoration:  #00aced;*/
    -webkit-text-decoration:  #AED6F1;
            text-decoration:  #AED6F1;
    cursor: pointer;
}
.pageHeaderModuleLinkBox{
    float: right;
    font-size: 18px;
    font-weight: bold;
    /*background-color: red;*/
    color:white;
    margin-top: 10px;
    margin-right: 10px;

}
.pageHeaderModuleLinkBox a{
    margin: 3px;
    z-index: 100;
}
.logoCompany{
    height: 50px;
    width: 250px;
    background-color: white;
    border-bottom-width: 1;
    border-bottom-color: black;
    border-radius: 5px;
    color: blue;
    margin-top: 5px;

}
.companyTitle{
    font-size: 24px;
    margin-left: 10px;
    vertical-align: center;
}
.pageHeaderModule{
    height: 60px;
    background-color: #264d73 ; /* #808080; #13B5EA;  #7b8188;*/
    color: white;
    vertical-align: center;
}

.pageHeaderMenu{
    height: 50px;
    background-color: #264d73 ; /* #808080; #13B5EA;*/
    color:white;
    padding-left: 60px;
    padding-top: 10px;
}

.pageHeaderMenu button{
    margin-left: 5px;
    height: 15px;
}
.pageHeaderMenuButton{
    margin-left: 5px;
    font-weight: bold;
    border-right-color: white;
    border-right-style: solid;
    border-right-width: 1px;
    padding-right: 10px;
}
.pageInfoSelected{
    padding-top: 5px;
}


.pageHeader{
    margin-top: -0px;
    height: 100px;
    background-color: white;
    color:black;
}


body{
    margin: 0;
}


.wallpaper {
    background-image: url(/static/media/background.aea5e277.png);

    /* Full height */
    height: auto;
    min-height: 100vh;
    width: 100%;

    /* Center and scale the image nicely */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top:0;
    bottom:0;
    overflow: auto;
}

.overlay{
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    top:0;
    bottom:0;
    height: 100%;
    width: 100%;
    z-index: -5;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}
.myTitleLogin{
    color: #7b8188;
    font-size: 16px;
    font-family: "cursive";
    padding-bottom: 10px;
    text-underline: black;
    border-bottom-width: 1px;
    border-bottom-color: white;
    border-bottom-style: solid;

}
.loginContentBox{
   /* height: 53%;*/
    width: 40%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 5%;
}

.registerContentBox{
    width: 40%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 5%;
    overflow: scroll;
    max-height: 80%;
}



.centerMyDiv{
    margin: 0 auto 0;
}


table.myTableBorder {
    border-spacing: 1em .5em;
    padding: 0 2em 1em 0;
    border: 1px solid orange;
}

/*table  td{
    width: 1.5em;
    height: 1.5em;
    background: #d2d2d2;
    text-align: center;
    vertical-align: middle;
}
table, td, th {
    border: 1px solid black;
}*/

.clickMe{
    cursor: pointer;
}

.pageContentBox{

   /* width: 100%;
    min-height: 75%;*/
    width: 1250%;
    min-height: 75%;
    background-color: white;
    border-radius: 10px;
    border-color: #7b8188;
    border-width: 2px;
    margin: 0 auto 0;
    margin-top: 2%;
}

.headerLogout{
    color: lightcoral;
    text-decoration: underline;

}

.headerMenuSelectSecondBar{
    color: yellow;
    font-weight: bold;
    font-size: 16px;
}
.borderRight{
    border-right-width: 1;
    border-right-color: black;
    border-right-style: solid;
}
.card-header{
    background-color: gray;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
}

.App {
    text-align: center;
    min-height: 70%;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: white;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

/*
    this our calendar css
 */
.myContainer{
    margin: 50px auto 50px;
    height: 100%;
    min-height: 100%;
    background-color: #00aced;
}
.calendar-container,
.calendar,
.calendar-header,
td,
tr {
    padding: 0;
    margin: 0;
}

.calendar-container {
    border: 2px solid skyblue;

}

.calendar {
    background-color: white;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

.calendar-header {
    border-bottom: 1px dashed skyblue;
    font-size: 1.6em;
}

.calendar-header td {
    border-spacing: 0;
    padding-left: 5px;
}

.day,
.empty-slot,
.week-day {
    text-align: center;
    height: 40px;
}

.week-day {
    font-size: 0.8em;
}

.day {
    font-size: 0.8em;
}

.day {
    cursor: pointer;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.current-day {
    background-color: #4285f4;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 2%;
    color: white;
}
.selected-day {
    background-color:yellow;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 2%;
    color: black;
}

.month-popup {
    position: absolute;
    padding: 5px;
    background: white;
    border: 2px solid skyblue;
}

.month-popup div {
    padding: 0.3em;
}

.nav-month {
    position: absolute;
    top: 0.6em;
    font-size: 0.6em;
    right:3px;
}

.prev {
}

.next {
}

.editor-year {
    max-width: 3.6em;
}

.label-month,
.label-year {
    font-size: 0.6em;
}

.label-month {
    cursor: pointer;
}
.myBorderLeftDiv{
    border-width: 2px;
    border-color: #00aced;
    border-radius: 5px;
}

.my-custom-scrollbar {
    position: relative;
    height: 100%;
    overflow-y: scroll;
}
.table-wrapper-scroll-y {
    display: block;
}
.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
